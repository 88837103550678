<template>
  <section class="py_30">
  <div class="d-flex align-items-center justify-content-center h-100">
    <div class="page_not_found text-center h-100 w-50">
       <h1 class="text-danger">404</h1>
						<h2 class="m-4 text-uppercase"> The page you are looking for is not found </h2>
                        <p> The page you are looking for does not exist. It may have been moved, or removed altogether. Perhaps you can return back to the site’s homepage and see if you can find what you are looking for. </p>
						<div class="separator  transparent center  " style="margin-top:35px;"></div>
						<p><a itemprop="url" class="btn btn_primary m-4" href="/"> Back to homepage </a></p>
					</div>
  </div>
  </section>
</template>
<script>
import Vue from "vue";
import VueRouter from "vue-router";
import $ from "jquery";
Vue.use(VueRouter);
export default {
  name: "404",
  beforeMount() {    
    document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
    const arr =  this.$route.path.split('/');
    console.log(arr[1]);
    if(arr[1] == 'airbnb'){
        console.log("d");
        $('#main-div').addClass("customer-a45");
    }
  },
};
</script>
